import { geoLocationState } from "../atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect } from "react";
import { Coordinates } from "../../Interfaces";



const useGeolocation = () => {
  const currentLocation = useRecoilValue(geoLocationState);
  const setCurrentLocation = useSetRecoilState(geoLocationState);

  useEffect(() => {
    const updateLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({ latitude, longitude });
          },
          (error) => console.error("Erro ao obter localização:", error),
          { enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocalização não suportada.");
      }
    };

    updateLocation();
    const intervalId = setInterval(updateLocation, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const haversineDistance = (coords1: Coordinates, coords2: Coordinates): number => {
    const toRad = (value: number): number => (value * Math.PI) / 180;

    const R = 6371e3; // Raio da Terra em metros
    const lat1 = toRad(coords1.latitude);
    const lat2 = toRad(coords2.latitude);
    const deltaLat = toRad(coords2.latitude - coords1.latitude);
    const deltaLon = toRad(coords2.longitude - coords1.longitude);

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) *
      Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Retorna a distância em metros
  };


  return { currentLocation, haversineDistance };
};

export default useGeolocation;
