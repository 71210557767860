import '../../../UI/Pages/Checkin/Instructions.css'
import DefaultButton from '../../Common/Buttons/DefaultButton';
import CheckinBackground from "./CheckinBackground";
import arrow_right from '../../../Assets/Icons/icon-arrow-right.svg';
import plus_white from '../../../Assets/Icons/plus-white.svg';
import minus_pink from '../../../Assets/Icons/minus-pink.svg';
import { useState } from 'react';
// import InstallButton from '../../Common/Buttons/InstallButton';

interface Props {
    setPage: any;
}

const Instructions: React.FC<Props> = ({ setPage }) => {
    const [collapse, setCollapse] = useState(false);

    const deviceType = detectDevice();
    const installMessage = deviceType === 'Android'
        ? '(Clique em Opções > Adicionar à tela de inicial)'
        : '(Clique em Compartilhar > Adicionar à Tela de Início)';

    return (
        <CheckinBackground>
            <div className="instructions-container">
                <p className='instructions-main-p' style={{ textAlign: 'center', marginBottom: '1.5rem' }}>O app para interagir com o crush em eventos.</p>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                    <div style={{ width: '60%', marginBottom: '1.5rem', marginTop: '0.5rem' }}>
                        <DefaultButton type={'button'} text='ENTRAR' iconRight={arrow_right} onClick={() => setPage(1)} />
                    </div>
                </div>


                <div className='how-it-works-container'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} onClick={() => setCollapse(prev => !prev)}>
                        <p className='instructions-main-p' style={{ fontSize: '1rem', color: !collapse ? '#FFFFFF' : '#FF6D8B' }}>Como funciona o Blinker?</p>
                        <img src={!collapse ? plus_white : minus_pink} alt='open' />
                    </div>
                    {collapse &&
                        <div>
                            <div className='instructions-items-container'>
                                <div className="instructions-item">
                                    <div className="instructions-item-background">
                                        <p className="instructions-item-number">1</p>
                                    </div>
                                    <p className="instructions-item-p">Faça check-in no local e preencha o cadastro</p>
                                </div>
                                <div className="instructions-item">
                                    <div className="instructions-item-background">
                                        <p className="instructions-item-number">2</p>
                                    </div>
                                    <p className="instructions-item-p">Selecione a pessoa com quem deseja interagir</p>
                                </div>
                                <div className="instructions-item">
                                    <div className="instructions-item-background">
                                        <p className="instructions-item-number">3</p>
                                    </div>
                                    <p className="instructions-item-p">Escolha a interação e envie</p>
                                </div>
                                <p className='instructions-subtitle-p'>Ao receber uma interação, você pode retribuir, ou partir para uma conversa pessoalmente.</p>
                            </div>

                            <div className='instructions-tips-container'>
                                <p className='instructions-tip-p'>Para uma melhor experiência:</p>
                                <p className='instructions-tip-p'><strong>Permita notificações:</strong> seja avisado quando alguém interagir com você.</p>
                                <p className='instructions-tip-p'><strong>Adicione o ícone na tela inicial:</strong> para usar o Blinker como app {installMessage}.</p>

                                {/* <InstallButton /> */}
                            </div>
                            <div style={{ marginTop: '1.5rem', marginBottom: '0.25rem' }}>
                                <DefaultButton
                                    onClick={() => window.open("https://www.instagram.com/reel/C_vs_QJPdj8/?igsh=MTB3bGxscmd6bW15cg==", "_blank")}
                                    text="Assista o video"
                                    type={"button"}
                                    backgroundColor="#262627"
                                    textColor="#FF6D8B"
                                    border="2px solid #FF6D8B" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </CheckinBackground>
    )
}


export default Instructions;


function detectDevice() {
    const userAgent = navigator.userAgent || (navigator as any).NavigatorID.vendor || (window as any).opera;

    // Verifica se o dispositivo é iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return 'iOS';
    }

    // Verifica se o dispositivo é Android
    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // Caso o dispositivo não seja identificado como iOS ou Android
    return 'Android';
}