import '../../../UI/Pages/Checkin/Checkin.css'
import CheckinBackground from "./CheckinBackground";
import ItemList from "./ItemList";
import location_icon from '../../../Assets/Icons/icon-location.svg';
import icon_chevron_right from '../../../Assets/Icons/icon-chevron-right.svg';
import QrcodeButton from "../../Common/Buttons/QrcodeButton";
import DefaultTitle from "../../Common/DefaultTitle";
import PageTransition from '../../Common/PageTransition';
import useCurrentUser from '../../State/Hooks/useCurrentUser';
import { useEffect, useState } from 'react';
import useGeolocation from '../../State/Hooks/useGeolocation';
import SearchInput from '../../Common/Inputs/SearchInput';
import DefaultModal from '../../Common/DefaultModal';
import OutRange from './OutRange';

interface Props {
    setPage?: (page: any) => void;
}

const Checkin: React.FC<Props> = ({ setPage = () => { } }) => {
    const { roomsList } = useCurrentUser();
    const { currentLocation, haversineDistance } = useGeolocation();
    const [nearbyRooms, setNearbyRooms] = useState<any[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [showRangeModal, setShowRangeModal] = useState(false);

    useEffect(() => {
        if (currentLocation && roomsList?.length > 0) {
            const newList = roomsList.filter((room: any) => {
                const [roomLatitude, roomLongitude] = room.id.split(",").map(Number);
                const distance = haversineDistance(currentLocation, { latitude: roomLatitude, longitude: roomLongitude });
                return distance <= 80000;
            });
            setNearbyRooms(newList);
        }
    }, [currentLocation, roomsList]);

    useEffect(() => {
        if (currentLocation && roomsList?.length > 0) {
            const newList = roomsList
                .map((room: any) => {
                    const [roomLatitude, roomLongitude] = room.id.split(",").map(Number);
                    const distance = haversineDistance(currentLocation, { latitude: roomLatitude, longitude: roomLongitude });
                    return { ...room, distance };
                })
                .filter((room: any) =>
                    room.distance <= 80000 &&
                    (!searchInput || room.title.toLowerCase().includes(searchInput.toLowerCase())) 
                )
                .sort((a: any, b: any) => a.distance - b.distance);

            setNearbyRooms(newList);
        }
    }, [currentLocation, roomsList, searchInput]);

    const handleSearch = (e: any) => {
        setSearchInput(e.target.value);
    }

    return (
        <CheckinBackground>
            <PageTransition>
                <div className="checkin-container">
                    <DefaultTitle text="Locais perto de você:" />
                    <SearchInput placeholder={'Buscar local...'} value={searchInput} onChange={handleSearch} />
                    {nearbyRooms?.map((room: any) =>
                        <ItemList
                            key={room.id}
                            room={room}
                            rightIcon={location_icon}
                            leftIcon={icon_chevron_right}
                            setPage={setPage}
                            setShowRangeModal={setShowRangeModal}
                        />
                    )}

                </div>
                {/* <div className="qrcode-div">
                    <QrcodeButton />
                    </div> */}
            </PageTransition>
            <DefaultModal show={showRangeModal} onClose={() => setShowRangeModal(false)} showCloseButton={true}>
                <OutRange />
            </DefaultModal>
        </CheckinBackground>
    )
}


export default Checkin;