import { updateUser } from '../../../lib/firebase/firestore';
import '../../../UI/Pages/Checkin/ItemList.css';
import SquareIcon from '../../Common/SquareIcon';
import useCurrentUser from '../../State/Hooks/useCurrentUser';
import { Timestamp } from "firebase/firestore";
import time_icon from '../../../Assets/Icons/icon-time-small-gray.svg';
import useGeolocation from '../../State/Hooks/useGeolocation';

interface Props {
    leftIcon?: string;
    rightIcon?: string;
    setPage: any;
    room?: any;
    setShowRangeModal?: any;
}

const ItemList: React.FC<Props> = ({ leftIcon, rightIcon, setPage, room, setShowRangeModal }) => {
    const { currentUser, setCurrentUser } = useCurrentUser();
    const { currentLocation, haversineDistance } = useGeolocation();
    const today = new Date().toLocaleString('en-US', { weekday: 'long', timeZone: 'America/Sao_Paulo' }).toLowerCase();
    const todaySchedule = room?.schedule[today];

    return (
        <div className={room?.open ? "item-container" : "item-container-closed"} onClick={() => {
            if (!room?.open) return;

            const [roomLatitude, roomLongitude] = room.id.split(",").map(Number);
            const distance = haversineDistance(currentLocation, { latitude: roomLatitude, longitude: roomLongitude });

            if (distance <= 40) {
                setCurrentUser((prev: any) => ({ ...prev, roomId: room?.id }));
                if (currentUser?.id) {
                    updateUser(currentUser.id, { roomId: room?.id, entryTime: Timestamp.now() });
                }
                setPage(2);
            } else {
                setShowRangeModal(true);
            }
        }}>
            {rightIcon && <SquareIcon src={rightIcon} long={true} />}

            <div className={'center-div'} translate="no">
                <h2 className='h2-title'>{room?.title}</h2>
                {room?.address && <p className='p-text'>{room?.address}</p>}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}>
                    <img src={time_icon} />

                    {todaySchedule && (<>
                        <p className='open-p'>{todaySchedule?.openTime}</p>
                        <p className='open-p'> - </p>
                        <p className='open-p'>{todaySchedule?.closeTime}</p>
                        <p className='open-p'>|</p>
                    </>)}


                    <p className='open-p'>{room.open ? 'Aberto' : 'Fechado'}</p>
                </div>
            </div>

            {leftIcon && <img src={leftIcon} />}

        </div>
    )
}

export default ItemList;

