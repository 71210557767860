import React from "react";
import '../../../UI/Pages/Checkin/OutRange.css';
import icon_report_red from '../../../Assets/Icons/icon-report-red.svg';


const OutRange: React.FC = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            gap: '0.5rem',
            marginBottom: 15,
            marginTop: 18
        }}>
            <img src={icon_report_red} />
            <p className="title-out-range">Ops! Parece que você está fora do alcance.</p >

            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 15,marginTop: -10, width: '85%' }}>
                <p className="text-out-range">{`Você só pode fazer check-in quando estiver dentro do estabelecimento. Verifique sua localização e tente novamente!`}</p >
            </div>

        </div >
    )
};

export default OutRange;