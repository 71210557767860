import React from 'react';
import icon_search from '../../../Assets/Icons/icon-search.svg';
import icon_close_white from '../../../Assets/Icons/icon-close-white.svg';
import '../../../UI/Common/Inputs/SearchInput.css';

interface Props {
    placeholder: string;
    value: string;
    onChange: any;
}

const SearchInput: React.FC<Props> = ({ value, onChange, placeholder }) => {

    const handleClear = () => {
        const event = { target: { value: "" } };
        onChange(event);
    };

    return (
        <div className="search-input-container">
            
            {value
                ? <img className="search-icon" src={icon_close_white} alt="clear" onClick={handleClear}/>
                : <img className="search-icon" src={icon_search} alt="search" />
            }

            <input
                className={`search-input ${value ? "has-value" : ""}`}
                type="text"
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
}

export default SearchInput;