import { firestore } from './client'

import {
    collection,
    query,
    getDocs,
    getDoc,
    addDoc,
    QueryDocumentSnapshot,
    DocumentData,
    onSnapshot,
    deleteDoc,
    setDoc,
    doc,
    where,
    DocumentSnapshot,
    serverTimestamp,
    Timestamp,
    arrayUnion,
    updateDoc,
    orderBy
} from "firebase/firestore";

function parseDoc(doc: QueryDocumentSnapshot<DocumentData, DocumentData> | DocumentSnapshot): any {
    return {
        id: doc.id,
        ...doc.data()
    }
}

export async function createUser(data: any, userId: string) {
    const userDocRef = doc(firestore, 'users', userId);
    await setDoc(userDocRef, { ...data, createdAt: serverTimestamp() });
    return parseDoc(await getDoc(userDocRef));
}

export async function sendMessage(userFrom: string, userFromName: string, userFromPhoto: string, userTo: string, message: any, roomId: string) {
    const data = {
        userFrom,
        userFromName,
        userFromPhoto,
        userTo,
        message,
        createdAt: serverTimestamp(),
        closed: true,
        roomId
    }
    const newMessage = await addDoc(collection(firestore, 'messages'), data);
    return newMessage.id;
}

export function getMessagesToSnapshot(userTo: string, cb: any) {
    const q = query(collection(firestore, "messages"), where("userTo", "==", userTo));

    const unsubscribe = onSnapshot(q, snapshot => {
        const results = snapshot.docs.map(parseDoc);
        cb(results);

    });
    return unsubscribe;
}

export async function updateUser(userId: string, data: any, merge: boolean = true) {
    await setDoc(doc(firestore, `users/${userId}`), data, { merge });
}

export async function getUser(userId: string) {
    const userDoc = await getDoc(doc(firestore, 'users', userId));
    if (userDoc.exists()) {
        return { id: userDoc.id, ...userDoc.data() };
    } else {
        return null;
    }
}

export async function getUserByPhone(phone: string) {
    const q = query(collection(firestore, 'users'), where('phone', '==', phone));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        return { id: userDoc.id, ...userDoc.data() };
    } else {
        return null;
    }
}

export function getUsersSnapshot(roomId: string, cb: any) {
    const q = query(
        collection(firestore, "users"),
        where("photo", "!=", false),
        where("roomId", "==", roomId)
    );
    const unsubscribe = onSnapshot(q, snapshot => {
        const results = snapshot.docs.map(parseDoc);
        cb(results);

    });
    return unsubscribe;

}

export async function deleteUser(userId: string) {
    await deleteDoc(doc(firestore, `users/${userId}`));
}


export function formatTimestamp(timestamp: Timestamp): string {
    const date = timestamp.toDate();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export async function updateMessageClosedStatus(messageId: string, closed: boolean, merge: boolean = true) {
    await setDoc(doc(firestore, `messages/${messageId}`), { closed }, { merge });
}


export function getRoomsSnapshot(cb: any) {
    const q = query(collection(firestore, "rooms"), where("active", "==", true));
    const unsubscribe = onSnapshot(q, snapshot => {
        const results = snapshot.docs.map(parseDoc);
        cb(results);
    });
    return unsubscribe;
}

export async function strikeUser(userId: string, reporterId: string, reason: string) {
    const userDocRef = doc(firestore, `users/${userId}`);
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const currentStrikes = userData.strikes || [];
        const hasReported = currentStrikes.some((strike: any) => strike.reporterId === reporterId);

        if (!hasReported) {
            await setDoc(userDocRef, {
                strikes: arrayUnion({
                    reporterId: reporterId,
                    reason: reason,
                    timestamp: new Date().toISOString()
                })
            }, { merge: true });
        }
    }
}

export async function sendFeedback(userId: string, likeMessage: string, changeMessage: string) {
    const userDocRef = doc(firestore, 'feedbacks', 'skate');

    const docSnap = await getDoc(userDocRef);
    if (!docSnap.exists()) {
        await setDoc(userDocRef, { feedbacks: [] });
    }

    await updateDoc(userDocRef, {
        feedbacks: arrayUnion({
            userId,
            likeMessage,
            changeMessage
        })
    });
}